<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :hideHeader="true"
    :title="$t(`${$route.meta.i18n}.title`)"
    :loading="false"
    :error="false"
    :isSettings="true"
  >
    <template v-slot:content>
      <div id="table">
        <!-- Gate log table -->
        <TableTemplate
          v-on:close="filterTempHandle = null"
          v-on:search="searchGateLogData"
          v-on:link="linkClickedEvent"
          v-on:update="updateReportData(true)"
          v-on:page="tablePageEvent"
          :hideHeader="true"
          :serverSearch="true"
          :searchable="true"
          :search="filterHandle.search"
          :group="false"
          :resetPage="resetTablePage"
          :actions="false"
          :dataTotal="serverTotalLogs"
          :loading="loadingData"
          :headers="headers"
          :error="errorRequest"
          :tableImage="displayVehicleImages"
          :src="serverTotalLogs == 0 ? [] : logs"
          :searchText="$t('tools.project.log.searchText')"
          :title="$t(`${$route.meta.i18n}.title`)"
        >
          <template v-slot:actions>
            <v-row
              class="ma-0"
              style="max-height: 40px; overflow: hidden"
              align="start"
            >
              <DateRangeInput
                :dense="true"
                :filter="true"
                :loading="false"
                :dateRange="filterHandle.dateRange"
                :key="updatedFilter"
                v-on:update="updateDateRange"
              />

              <FilterInput
                v-on:reset="removeFilterEvent"
                v-on:update="applyDataFilterEvent"
                v-on:close="cancelFilterEvent"
                :active="activeFilter"
                :filter="activeFilter"
              >
                <template v-slot:input>
                  <FilterSelection
                    class="filter-selection"
                    :key="updateFilter"
                    :filter="filterTempHandle"
                    :whitelistData="whitelistData"
                    v-on:update="filterTempHandle = $event"
                  />
                </template>
              </FilterInput>
            </v-row>

            <v-spacer />
            <IconButton
              class="mr-2"
              tooltipDelay="1000"
              :style="
                displayVehicleImages
                  ? 'border-radius: 50%; background-color: var(--v-primary-base);'
                  : ''
              "
              :buttonColor="displayVehicleImages ? 'white' : ''"
              v-on:click="displayVehicleImages = !displayVehicleImages"
              :tooltipDisabled="false"
              tooltipPosition="l"
              :tooltip="
                displayVehicleImages
                  ? $t('tools.project.log.tooltips.images.hide')
                  : $t('tools.project.log.tooltips.images.show')
              "
              icon="mdi-image-search"
            />

            <IconButton
              class="mr-1"
              tooltipDelay="1000"
              :style="
                displayOnSiteVehicles
                  ? 'border-radius: 50%; background-color: var(--v-primary-base);'
                  : ''
              "
              :buttonColor="displayOnSiteVehicles ? 'white' : ''"
              v-on:click="displayOnSiteVehicles = !displayOnSiteVehicles"
              :tooltipDisabled="false"
              tooltipPosition="l"
              :tooltip="
                displayOnSiteVehicles
                  ? $t('tools.project.log.tooltips.onSite.hide')
                  : $t('tools.project.log.tooltips.onSite.show')
              "
              icon="mdi-map-marker"
            />

            <v-divider vertical class="ma-1" />

            <DownloadVehicleLogs
              class="mr-2 ml-1"
              :violations="false"
              :headers="headers"
              :whitelistData="whitelistData"
            />
          </template>

          <template v-slot:images="{data}">
            <div
              class="zoom"
              v-on:click="linkClickedEvent({col: 'licensePlate', data: data})"
              v-if="data != null && data.detectionImageUrl != null"
            >
              <v-img
                preload
                style="
                  border-radius: 10px !important;
                  overflow: hidden !important;
                "
                width="140"
                :src="data.detectionImageUrl"
              />
            </div>
          </template>
          <!-- table column: Barrier -->
          <template v-slot:barrier="{data}">
            <div style="width: 30px !important">
              <v-icon
                v-if="data.barrier != null && data.barrier == true"
                color="success"
                size="20"
              >
                mdi-check-circle
              </v-icon>

              <v-icon v-else size="17" class="ml-1" style="opacity: 0.3"
                >mdi-close-circle-outline</v-icon
              >
            </div>
          </template>

          <!-- table column: country -->
          <template v-slot:plate="{data}">
            <div class="expand">
              <LicensePlateTemplate
                style="cursor: pointer !important"
                dense
                :country="data.country"
                :licensePlate="data.licensePlate"
              />
            </div>
          </template>
          <!-- table column: country -->
          <!-- displaying only country flag -->
          <template v-slot:country="{data}">
            <country-flag
              class="country-flag"
              :country="data.country"
              size="normal"
            />
          </template>

          <!-- table column: direction -->
          <template v-slot:direction="{data}">
            <!-- case: leave & enter -->
            <v-row
              v-if="data.direction != 0 && data.direction != null"
              class="ma-0"
            >
              <v-icon v-if="data.direction == 1" size="17" color="black">
                mdi-location-enter
              </v-icon>

              <v-icon v-if="data.direction == 2" size="17" color="black">
                mdi-location-exit
              </v-icon>

              <span class="ml-1">
                <span v-if="data.direction == 1"
                  ><b>{{
                    $t('tools.project.log.dialog.gateLog.directionEnter')
                  }}</b>
                  <span
                    class="ml-1"
                    v-show="data.gate != null && !displayVehicleImages"
                    >({{ data.gate }})</span
                  ></span
                >
                <span v-else-if="data.direction == 2"
                  ><b>{{
                    $t('tools.project.log.dialog.gateLog.directionExit')
                  }}</b>
                  <span
                    class="ml-1"
                    v-show="data.gate != null && !displayVehicleImages"
                    >({{ data.gate }})</span
                  ></span
                >
                <!-- enter -->
              </span>
            </v-row>

            <!-- case: unknown -->
            <div v-else style="font-size: 14px" class="grey--text">
              {{ $t('tools.project.log.gates.unknown') }}
            </div>
          </template>

          <!-- table column: whitelist -->
          <template v-slot:whitelist="{data}">
            <div>
              <!-- case: existing whitelist -->
              <span
                v-if="data.whitelist != null && data.whitelist.length > 0"
                style="font-size: 14px"
              >
                {{ data.whitelist }}
              </span>

              <span v-else style="font-size: 13px" class="grey--text">{{
                $t('tools.project.log.table.noVehicleGroup')
              }}</span>
              <!-- case: no whitelist  -->
              <!-- add whitelist to vehicle -->
              <!-- <div v-else>

                <v-edit-dialog v-on:open="openListDialogEvent(data)" :key="updateWhitelistDialog" style="width: 100px!important">

                      <div >
                        <v-row class="ma-0" align="center" style="cursor: pointer;">
                          <v-icon size="15" color="grey">mdi-plus</v-icon>
                          <span class="grey--text" style="font-size: 13px; ">{{$t('tools.project.log.table.addListDialog.buttonText')}}</span>
                          
                        </v-row>
                      </div>

                  <template v-slot:input>
                    <div style="position: relative; width: 350px" class="pb-2 pt-1" >

                      <div class="pt-3 pb-2">
                        
                        <div style="margin-bottom: 2px;">
                          <b style="font-size: 14px;">{{$t('tools.project.log.table.addListDialog.selectGroupText')}}:</b>
                        </div>

                        <SelectorInput 
                          :value="addWhitelistDialog.selectedList" 
                          v-on:update="addWhitelistDialog.selectedList = $event"
                          :noSelectionText="$t('tools.project.log.table.addListDialog.noSelection')" 
                          :noSelection="true"
                          :data="whitelistData != null ? whitelistData.map(whitelist => { return { id: whitelist.id, text: whitelist.name } }) : []" 
                        />




                        <Checkbox 
                        class="mt-2"
                          v-on:update="addWhitelistDialog.applyAll = $event" 
                          :value="addWhitelistDialog.applyAll"
                          :text="$t('tools.project.log.table.addListDialog.checkbox')" 
                        />

                          <v-alert  text
                            filled
                            dense
                            style="font-size: 14px; "
                            color="grey"
                            class="mt-3 ml-1 mr-1 primary--text">

                            Die Änderung gilt erst für alle zukünftigen Events. Alle vergangenen Events werden nicth verändert.
                            
                </v-alert>

                      </div>


                      <v-row class="ma-0 mt-0" justify="end" align="center" style="user-select: none;">

                        <v-spacer />

                        <TextButton 
                          :loading="addWhitelistDialog.loading" 
                          :disabled="addWhitelistDialog.selectedList == null"
                          v-on:click="addVehicleWhitelist()" 
                          dense 
                          buttonColor="primary" 
                          :text="$t('helper.buttons.submit')" 
                        />
                      </v-row>

                    </div>
                  </template>
                </v-edit-dialog>


              </div> -->
            </div>
          </template>

          <template v-slot:vehicleLog="{data}">
            <div style="width: 50px !important">
              <VehicleLogListInformation
                v-if="
                  data.vehicleLog != null &&
                  data.vehicleLog.duration != null &&
                  data.vehicleLog.duration >= 0
                "
                :data="data"
              />

              <div style="width: 50px !important">
                <VehicleLogListInformation
                  v-if="
                    data.vehicleLog != null &&
                    data.vehicleLog.duration != null &&
                    data.vehicleLog.duration >= 0
                  "
                  :data="data"
                />

                <v-icon
                  v-else-if="
                    data.vehicleLog != null && data.vehicleLog.duration == -1
                  "
                  size="17"
                  class="ml-1"
                  style="opacity: 0.2"
                  >mdi-car</v-icon
                >
                <v-icon v-else size="17" class="ml-1" style="opacity: 0.3"
                  >mdi-car-off</v-icon
                >
              </div>
            </div>
          </template>
        </TableTemplate>
      </div>

      <v-dialog
        v-show="gatelogDialog.data != null"
        v-model="gatelogDialog.status"
        width="500"
      >
        <GateLogInformation
          v-on:filter="filterGateLogEvent"
          :data="gatelogDialog.data"
          v-on:close="gatelogDialog.status = false"
        />
      </v-dialog>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
import TableTemplate from '../../admin/TableTemplate.vue';
import CountryFlag from 'vue-country-flag';

import GateLogInformation from '../../../../components/tools/project/gatereport/dialog/GateLogInformation.vue';
import VehicleLogListInformation from '../../../../components/tools/project/gatereport/dialog/VehicleLogListInformation.vue';

import DownloadVehicleLogs from '../../../../components/tools/project/gatereport/dialog/DownloadVehicleLogs.vue';
import DateRangeInput from '@components/text/DateRangeInput.vue';
import FilterInput from '@components/text/FilterInput.vue';

import FilterSelection from '../../../../components/tools/project/gatereport/FilterSelection.vue';
// import TextButton from '@components/button/TextButton.vue';
// import SelectorInput from '@components/text/SelectorInput.vue';
// import Checkbox from '@components/text/Checkbox.vue';
import LicensePlateTemplate from '../../../../components/tools/project/gatereport/LicensePlateTemplate.vue';
import IconButton from '../../../../../ui-components/src/components/button/IconButton.vue';

export default {
  name: 'ProjectViewGateReport',
  components: {
    ProjectPageTemplate,
    TableTemplate,
    CountryFlag,
    GateLogInformation,
    VehicleLogListInformation,
    DownloadVehicleLogs,
    DateRangeInput,
    FilterInput,
    FilterSelection,
    // TextButton,
    // SelectorInput,
    // Checkbox,
    LicensePlateTemplate,
    IconButton,
  },

  data() {
    return {
      errorRequest: false,
      displayVehicleImages: false,
      displayOnSiteVehicles: false,
      paginationSettings: {
        page: 1,
        itemsPerPage: 20,
      },
      updatedFilter: false,
      serverTotalLogs: 0,
      projectGateList: null,

      updateWhitelistDialog: false,

      updateFilter: false,
      filterTempHandle: null,
      filterHandle: {
        dateRange: null,
        sortDescending: false,
        sortBy: 'TIMESTAMP',
        search: '',
        minDuration: 0,
        whitelistId: null,
        violations: false,
        gate: null,
      },
      resetTablePage: false,

      gatelogDialog: {
        status: false,
        data: null,
      },
      whitelistData: null,
      loadingData: false,

      logs: null,
    };
  },

  computed: {
    headers() {
      var header = [
        {
          text: this.$t('tools.project.log.table.licensePlate'),
          align: 'start',
          slot: 'plate',
          link: true,
          value: 'licensePlate',
          width: '230px',
          bold: true,
          sortable: false,
        },
        {
          text: this.$t('tools.project.log.table.gate'),
          value: 'direction',
          slot: 'direction',
          sortable: false,
        },
        {
          text: this.$t('tools.project.log.table.date'),
          value: 'timestamp',
          date: true,
          datetime: true,
          width: '200px',
          sortable: false,
        },
        {
          text: this.$t('tools.project.log.table.whitelist'),
          value: 'whitelist',
          slot: 'whitelist',
          link: true,
          width: '250px',
          sortable: false,
        },
        {
          text: this.$t('tools.project.log.table.country'),
          value: 'country',
          slot: 'country',
          sortable: false,
          width: '50px',
          align: 'center',
        },
      ];

      if (this.displayOnSiteVehicles != true) {
        header = [
          {
            text: '',
            value: 'isMatched',
            slot: 'vehicleLog',
            sortable: false,
            width: '30px',
          },
          header,
        ].flat();
      }

      if (
        this.whitelistData != null &&
        this.whitelistData.some(
          (list) => list != null && list.allowedTime != null,
        )
      ) {
        header = [
          {
            text: '',
            value: 'barrier',
            slot: 'barrier',
            width: '30px',
            bold: true,
            sortable: false,
          },
          header,
        ].flat();
      }

      if (this.displayVehicleImages == true) {
        return [
          {
            text: this.$t('tools.project.log.table.image'),
            link: true,
            value: null,
            slot: 'images',
            width: '140px',
            bold: true,
            sortable: false,
          },
          header,
        ].flat();
      } else {
        return header;
      }
    },
    activeFilter() {
      return (
        this.filterHandle.sortDescending != false ||
        this.filterHandle.sortBy != 'TIMESTAMP' ||
        this.filterHandle.minDuration != 0 ||
        this.filterHandle.whitelistId != null ||
        this.filterHandle.violations != false ||
        this.filterHandle.gate != null
      );
    },
  },

  created() {
    // apply query filters
    if (this.$route.query != null) {
      if (this.$route.query.whitelistId != null) {
        this.filterTempHandle = this.filterHandle;
        this.filterTempHandle.whitelistId = this.$route.query.whitelistId;
      } else if (this.$route.query.plate != null) {
        this.filterHandle.search = this.$route.query.plate;
        return;
      }
    }

    this.updateReportData();
  },

  watch: {
    displayOnSiteVehicles() {
      if (this.displayOnSiteVehicles == true) {
        this.filterHandle.dateRange = null;

        if (this.displayOnSiteVehicles == true) {
          this.$toast.info(this.$t('tools.project.log.toasts.info.onSiteData'));
        }
      }
      this.removeFilterEvent();
      // this.filterReportData()
    },

    filterHandle() {
      this.filterTempHandle = this.filterHandle;
      // this.filterReportData()
    },
    // searchValue() { // console.log("2"); return this.filterReportData() },
    paginationSettings() {
      return this.filterReportData();
    },
  },

  methods: {
    filterGateLogEvent(licensePlate) {
      this.gatelogDialog.status = false;
      this.searchGateLogData(licensePlate);
    },
    searchGateLogData(eventHandle) {
      this.filterHandle.search = eventHandle;

      this.filterTempHandle = this.filterHandle;

      if (
        this.$route.query != null &&
        this.$route.query.plate != null &&
        this.$route.query.plate != eventHandle
      )
        this.$router.replace({query: {}});
      this.filterReportData();
    },
    tablePageEvent(eventHandle) {
      if (
        this.paginationSettings == null ||
        eventHandle.page != this.paginationSettings.page
      ) {
        this.paginationSettings = eventHandle;
      }
    },
    updateDateRange(eventHandle) {
      if (eventHandle == null) this.filterHandle.dateRange = null;
      else {
        this.displayOnSiteVehicles = false;
        this.filterHandle.dateRange = eventHandle;
      }

      this.filterTempHandle = this.filterHandle;

      this.updatedFilter = !this.updatedFilter;
      this.filterReportData();
    },

    cancelFilterEvent() {
      this.filterTempHandle = this.filterHandle;
      setTimeout(() => {
        this.updateFilter = !this.updateFilter;
      }, 250);
    },

    applyDataFilterEvent() {
      if (this.filterTempHandle == null) return;
      // trigger DOM & data update
      this.updateFilter = !this.updateFilter;
      this.resetTablePage = !this.resetTablePage;

      // copy filter temp data
      this.filterHandle = this.filterTempHandle;

      // send request
      this.filterReportData();
    },

    removeFilterEvent() {
      // set filter & temp handle to default values
      this.filterHandle = {
        dateRange: this.filterHandle.dateRange,
        sortBy: 'TIMESTAMP',
        minDuration: 0,
        whitelistId: null,
        violations: false,
        gate: null,
        sortDescending: false,
      };

      this.filterTempHandle = this.filterHandle;

      if (this.$route.query != null && this.$route.query.id != null)
        this.$router.replace({query: {}});

      this.resetTablePage = !this.resetTablePage; // reset table page
      return this.filterReportData();
    },

    linkClickedEvent(eventHandle) {
      if (eventHandle.col == 'licensePlate') {
        this.gatelogDialog.data = eventHandle.data;
        this.gatelogDialog.status = true;
      } else if (eventHandle.col == 'whitelist') {
        if (eventHandle.data.whitelistId != null)
          return this.$router.push({
            name: 'project_whitelist',
            query: {id: eventHandle.data.whitelistId},
          });
        else return this.$router.push({name: 'project_whitelist'});
      }
    },

    async filterReportData() {
      this.loadingData = true;
      this.errorRequest = false;

      var gatelogResp = await this.$api.gatereport.gatelog(
        this.$route.params.projectId,
        {
          ...this.filterHandle,
          page: this.paginationSettings.page,
          items: this.paginationSettings.itemsPerPage,
        },
        this.displayOnSiteVehicles,
      );
      console.log('RE', this.$route.params, gatelogResp);

      if (gatelogResp.success == true) {
        // // console.log(gatelogResp.data)
        this.serverTotalLogs = gatelogResp.data.pagination.total;

        if (this.serverTotalLogs == 0) {
          this.logs = [];
        } else {
          this.logs = gatelogResp.data.results.map((log) => {
            // console.log(log)

            var sortedData;
            if (log.whitelistId != null && this.whitelistData != null) {
              const whitelistHandle = this.whitelistData.reduce((a, b) =>
                a.id == log.whitelistId ? a : b,
              );
              sortedData = {
                ...log,
                whitelist:
                  whitelistHandle != null ? whitelistHandle.name : null,
                whitelistData: whitelistHandle,
                barrier:
                  whitelistHandle != null
                    ? whitelistHandle.writeToDevice
                    : false,
                isMatched: log.vehicleLog != null,
              };
            } else sortedData = {...log, isMatched: log.vehicleLog != null};

            if (
              this.projectGateList != null &&
              this.projectGateList[log.deviceId] != null
            ) {
              sortedData = {
                ...sortedData,
                gate: this.projectGateList[log.deviceId],
              };
            }

            return sortedData;
          });

          console.log(this.logs);
        }
      } else {
        this.$toast.error(
          this.$t('tools.project.log.toasts.error.failedLoadingData'),
        );
        this.logs = [];
        this.errorRequest = true;
      }

      this.loadingData = false;
    },

    async updateReportData() {
      this.loadingData = true;

      const resp = await Promise.all([
        this.$api.gatereport.whitelists(this.$route.params.projectId),
        this.$api.gatereport.projectGates(this.$route.params.projectId),
      ]);
      console.log('GL', resp);

      if (resp[0].success == true) {
        this.whitelistData = resp[0].data.whitelists;
        // console.log(resp[1])

        if (resp[1].success == true) {
          this.projectGateList = resp[1].data;
        }
        await this.filterReportData();
      } else {
        this.$toast.error(
          this.$t('tools.project.log.toasts.error.failedLoadingData'),
        );
      }

      this.loadingData = false;
    },
  },
};
</script>

<style scoped>
.dashboard-area {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.filter-selection {
  width: 400px;
}

.country-flag {
  border-radius: 10px;
  transform: scale(0.45);
}

.zoom {
  transition: transform 0.2s;
  /* Animation */
  width: 140px;
  aspect-ratio: 16/9;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 10px !important;
  background-color: #e0e0e0;
  overflow: hidden !important;
}

.zoom:hover {
  transform: scale(1.06);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.expand {
  overflow: hidden;
}

.expand:hover {
  transform: scale(1.03) !important;
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

  transition: transform 50ms linear 50ms;
  transform-origin: center center;
  animation: normal;
}
</style>
