<template>
  <DialogTemplate
    style="user-select: none"
    oncontextmenu="return false;"
    :title="
      $t('tools.project.log.dialog.gateLog.title', {
        id:
          data != null && data.id != null
            ? `Nr. ${$helper.formatID(data.id)} (${data.licensePlate})`
            : '',
      })
    "
    v-on:close="$emit('close')"
  >
    <template v-slot:content>
      <!-- {{data}} -->
      <div class="image mb-3">
        <v-hover v-slot="{hover}">
          <div
            style="
              border-radius: 10px;
              height: 100%;
              width: 100%;
              position: relative;
            "
          >
            <div
              v-on:click="
                $helper.copy2Clipboard(
                  data.licensePlate,
                  $t('helper.text.licensePlate'),
                )
              "
              class="license-plate-overlay"
            >
              <LicensePlateTemplate
                style="cursor: pointer"
                :country="data.country"
                :licensePlate="data.licensePlate"
              />
            </div>

            <inner-image-zoom
              :key="data.id"
              hideHint
              :src="data.detectionImageUrl"
            />
            <IconButton
              v-on:click="downloadGateImage()"
              v-show="hover"
              class="download-overlay"
              buttonColor="black"
              icon="mdi-download"
            />
          </div>
        </v-hover>
      </div>

      <div style="width: 100%">
        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.licensePlate')
          }}</template>
          <template v-slot:value>
            <v-hover v-slot="{hover}">
              <v-row class="ma-0" align="center">
                <b
                  class="link-value"
                  v-on:click="
                    $helper.copy2Clipboard(
                      data.licensePlate,
                      $t('helper.text.licensePlate'),
                    )
                  "
                  >{{ data.licensePlate }}</b
                >
                <v-icon
                  class="ml-2"
                  style="margin-top: 2px"
                  size="14"
                  color="primary"
                  v-show="hover"
                  >mdi-content-copy</v-icon
                >
              </v-row>
            </v-hover>
          </template>
        </TableListRow>

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.country')
          }}</template>
          <template v-slot:value>
            <v-row
              v-if="$helper.countryName(data.country) != null"
              class="ma-0"
              align="center"
            >
              <country-flag
                style="border-radius: 10px"
                :country="data.country"
                size="small"
              ></country-flag>
              <span class="ml-2">{{ $helper.countryName(data.country) }}</span>
            </v-row>

            <span v-else>-</span>
          </template>
        </TableListRow>

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.direction')
          }}</template>
          <template v-slot:value>
            <v-row
              v-if="
                (data.direction == 1 || data.direction == 2) &&
                data.direction != null
              "
              class="ma-0 table-key"
            >
              <v-icon v-if="data.direction == 1" size="17" color="black">
                mdi-location-enter
              </v-icon>

              <v-icon v-if="data.direction == 2" size="17" color="black">
                mdi-location-exit
              </v-icon>
              <span class="ml-1">
                <span v-if="data.direction == 1"
                  ><b>{{
                    $t('tools.project.log.dialog.gateLog.directionEnter')
                  }}</b>
                  <span class="ml-1" v-show="data.gate != null"
                    >({{ data.gate }})</span
                  ></span
                >
                <span v-else-if="data.direction == 2"
                  ><b>{{
                    $t('tools.project.log.dialog.gateLog.directionExit')
                  }}</b>
                  <span class="ml-1" v-show="data.gate != null"
                    >({{ data.gate }})</span
                  ></span
                >
              </span>
            </v-row>

            <div v-else style="font-size: 14px" class="grey--text table-key">
              {{ $t('tools.project.log.dialog.gateLog.directionUnknown') }}
            </div>
          </template>
        </TableListRow>

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.timestamp')
          }}</template>
          <template v-slot:value>
            <v-hover v-slot="{hover}">
              <v-row class="ma-0" align="center">
                <span
                  class="link-value"
                  v-on:click="
                    $helper.copy2Clipboard(
                      $helper.formatDate(data.timestamp),
                      $t('helper.text.date'),
                    )
                  "
                  >{{ $helper.formatDate(data.timestamp) }}</span
                >
                <v-icon
                  class="ml-2"
                  style="margin-top: 2px"
                  size="14"
                  color="primary"
                  v-show="hover"
                  >mdi-content-copy</v-icon
                >
              </v-row>
            </v-hover>
          </template>
        </TableListRow>

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.whitelist')
          }}</template>
          <template v-slot:value>
            <div class="table-key">
              <span v-if="data.whitelist != null">{{ data.whitelist }}</span>
              <div
                v-else
                style="font-size: 13px; margin-top: 1px"
                class="grey--text"
              >
                {{ $t('tools.project.log.dialog.gateLog.noWhitelist') }}
              </div>
            </div>
          </template>
        </TableListRow>

        <TableListRow v-show="data.barrier != null">
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.barrier')
          }}</template>
          <template v-slot:value>
            <v-row
              v-if="data.barrier == true"
              style="font-size: 13px"
              class="ma-0 table-key success--text"
              align="center"
            >
              <v-icon class="mr-1" color="success" size="17"
                >mdi-check-circle</v-icon
              >
              <b>{{ $t('tools.project.log.dialog.gateLog.barrierOpened') }}</b>
            </v-row>

            <div
              v-else
              style="font-size: 13px; margin-top: 1px"
              class="grey--text table-key"
            >
              {{ $t('tools.project.log.dialog.gateLog.noBarrierControl') }}
            </div>
          </template>
        </TableListRow>

        <!-- <div style="position: relative;">
            <VehicleLogTimerange v-show="data.vehicleLog != null" class="mt-4" :data="data"/>
            <div style="position: absolute; right: 12px; top: 50%; transform: translateY(-50%);">
            <VehicleLogListInformation icon="mdi-chevron-right" :dense="false" :left="true" :data="data"/>
            
            </div>
            
            </div> -->
      </div>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pb-1" align="center" justify="start">
        <TextButton
          v-on:click="downloadLog()"
          icon="mdi-download"
          :text="$t('tools.project.log.dialog.gateLog.exportButton')"
        />
        <v-spacer />
        <IconButton v-on:click="searchGateLogsEvent()" icon="mdi-magnify" />
      </v-row>
    </template>
  </DialogTemplate>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
import TableListRow from '@components/table/TableListRow.vue';
import CountryFlag from 'vue-country-flag';
import TextButton from '@components/button/TextButton.vue';
import LicensePlateTemplate from '../LicensePlateTemplate.vue';
import IconButton from '@components/button/IconButton.vue';
import InnerImageZoom from 'vue-inner-image-zoom';

export default {
  name: 'GateLogInformation',
  props: {
    data: {
      required: true,
    },
  },

  components: {
    DialogTemplate,
    TableListRow,
    CountryFlag,
    TextButton,
    LicensePlateTemplate,
    IconButton,
    'inner-image-zoom': InnerImageZoom,
  },

  methods: {
    searchGateLogsEvent() {
      this.$emit('filter', this.data.licensePlate);
    },
    async downloadGateImage() {
      try {
        await this.$helper.download(
          this.data.detectionImageUrl,
          `export-image-${this.data.licensePlate}-${
            this.data.country
          }-${+new Date(this.data.timestamp)}`,
          'image',
          'png',
        );
      } catch (error) {
        this.$toast.error(
          this.$t('tools.project.log.toasts.error.downloadGateImage'),
        );
      }
    },
    async downloadLog() {
      this.loadingDownload = true;
      const res = await this.$pdf.createGateLog(
        {...this.data, projectName: this.$store.getters.project.general.name},
        true,
      );
      if (res != true)
        return this.$toast.error(
          this.$t('tools.project.log.toasts.error.createGateLog'),
        );
      this.loadingDownload = false;
    },
  },
};
</script>

<style scoped>
.image {
  max-width: 569px !important;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden !important;
  user-select: none !important;
  border-radius: 10px;
  background-color: rgba(220, 220, 220, 1);
}

.license-plate-overlay {
  position: absolute;
  z-index: 20;
  bottom: 4px;
  left: 10px;
}

.download-overlay {
  position: absolute;
  bottom: 9px;
  right: 10px;
  background-color: rgba(220, 220, 220, 0.75);
  border-radius: 50%;
}
.table-key {
  user-select: none !important;
}
.link-value {
  cursor: pointer;
  user-select: none !important;
}
.link-value:hover {
  color: var(--v-primary-base);
}
</style>
