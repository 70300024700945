<template>
  <v-menu
    v-if="data.isMatched == true"
    v-model="vehicleLogMenu"
    :close-on-content-click="false"
    offset-x
    :nudge-right="-10"
  >
    <template v-slot:activator="{on, attrs}">
      <div v-bind="attrs" v-on="on" style="border-radius: 50%">
        <IconButton
          :buttonColor="vehicleLogMenu ? 'primary' : ''"
          :dense="dense"
          :icon="icon"
        />
      </div>
    </template>
    <v-card width="450">
      <div class="pt-2 ml-3 mr-3 pb-3" style="overflow: hidden !important">
        <v-row class="ma-0 mb-2" align="center">
          <LicensePlateTemplate
            :country="data.country"
            :licensePlate="data.licensePlate"
          />

          <!-- <country-flag class="mb-0" style="border-radius: 10px;  " :country='data.country' size='normal'></country-flag> -->
          <v-spacer />
          <IconButton
            :loading="loadingDownload"
            v-on:click="downloadLog()"
            class="ml-3"
            icon="mdi-download"
          />
          <IconButton
            v-on:click="vehicleLogMenu = false"
            class="ml-2"
            icon="mdi-close"
          />
        </v-row>

        <VehicleLogTimerange :data="vehicleTimerangeData" />

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.table.licensePlate')
          }}</template>
          <template v-slot:value
            ><b>{{ data.licensePlate }}</b></template
          >
        </TableListRow>

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.table.country')
          }}</template>
          <template v-slot:value>
            <v-row
              v-if="$helper.countryName(data.country) != null"
              class="ma-0"
              align="center"
            >
              <country-flag
                style="border-radius: 10px"
                :country="data.country"
                size="small"
              ></country-flag>
              <span class="ml-2">{{ $helper.countryName(data.country) }}</span>
            </v-row>

            <span v-else>-</span>
          </template>
        </TableListRow>

        <TableListRow>
          <template v-slot:key>{{
            $t('tools.project.log.table.whitelist')
          }}</template>
          <template v-slot:value>
            <b v-if="data.whitelistData != null && data.whitelist != null">{{
              data.whitelist
            }}</b>
            <span v-else>-</span>
          </template>
        </TableListRow>
        <TableListRow v-show="data.barrier != null">
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.barrier')
          }}</template>
          <template v-slot:value>
            <v-row
              v-if="data.barrier == true"
              style="font-size: 13px"
              class="ma-0 success--text"
              align="center"
            >
              <v-icon class="mr-1" color="success" size="17"
                >mdi-check-circle</v-icon
              >
              <b>{{ $t('tools.project.log.dialog.gateLog.barrierOpened') }}</b>
            </v-row>

            <div
              v-else
              style="font-size: 13px; margin-top: 1px"
              class="grey--text"
            >
              {{ $t('tools.project.log.dialog.gateLog.noBarrierControl') }}
            </div>
          </template>
        </TableListRow>

        <TableListRow
          v-if="
            data.whitelistData != null &&
            data.whitelistData.allowedTime != null &&
            data.whitelistData.allowedTime > 0
          "
        >
          <template v-slot:key>{{
            $t('tools.project.log.dialog.gateLog.allowedTime')
          }}</template>
          <template v-slot:value
            ><b
              >{{ $helper.formatDuration(data.whitelistData.allowedTime) }} h
              <span
                :class="
                  data.vehicleLog.duration - data.whitelistData.allowedTime > 0
                    ? 'primary--text'
                    : 'grey--text'
                "
                class="ml-1"
                style="font-size: 13px"
              >
                <span
                  v-if="
                    data.vehicleLog.duration - data.whitelistData.allowedTime >
                    0
                  "
                >
                  +
                </span>
                <span v-else>-</span>
                {{
                  $helper.formatDuration(
                    data.vehicleLog.duration / 60 -
                      data.whitelistData.allowedTime,
                  )
                }}
                h</span
              >
            </b></template
          >
        </TableListRow>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import LicensePlateTemplate from '../LicensePlateTemplate.vue';
import TableListRow from '@components/table/TableListRow.vue';
import CountryFlag from 'vue-country-flag';
import IconButton from '@components/button/IconButton.vue';
import VehicleLogTimerange from '../VehicleLogTimerange.vue';

export default {
  name: 'VehicleLogListInformation',
  props: {
    left: {
      default: false,
    },
    data: {
      required: true,
    },
    icon: {
      default: 'mdi-car-multiple',
    },
    dense: {
      default: true,
    },
  },
  components: {
    LicensePlateTemplate,
    TableListRow,
    CountryFlag,
    IconButton,
    VehicleLogTimerange,
  },

  data() {
    return {
      vehicleLogMenu: false,
      loadingDownload: false,
    };
  },

  computed: {
    vehicleTimerangeData() {
      var initialDate = new Date(this.data.timestamp);
      var secondDate = new Date(
        initialDate.getTime() + this.data.vehicleLog.duration * 1000,
      );

      return {
        duration: this.data.vehicleLog.duration / 60,
        enterTimestamp: initialDate,
        exitTimestamp: secondDate,
      };
    },
  },

  methods: {
    async downloadLog() {
      this.loadingDownload = true;
      this.$nextTick(async () => {
        const resp = await this.$api.gatereport.vehicleLog(
          this.$route.params.projectId,
          this.data.vehicleLog.id,
        );
        if (resp.success == true) {
          // console.log(resp.data)
          var res = await this.$pdf.createVehicleLog(
            {
              ...resp.data,
              projectName: this.$store.getters.project.general.name,
            },
            undefined,
            true,
          );
          if (res != true) {
            this.$toast.error('PDF-Dokument konnte nicht erstellt werden.');
          }
        }

        this.loadingDownload = false;
      });
    },
  },
};
</script>

<style scoped></style>
