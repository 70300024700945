var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProjectPageTemplate',{staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"hideHeader":true,"title":_vm.$t(((_vm.$route.meta.i18n) + ".title")),"loading":false,"error":false,"isSettings":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{attrs:{"id":"table"}},[_c('TableTemplate',{attrs:{"hideHeader":true,"serverSearch":true,"searchable":true,"search":_vm.filterHandle.search,"group":false,"resetPage":_vm.resetTablePage,"actions":false,"dataTotal":_vm.serverTotalLogs,"loading":_vm.loadingData,"headers":_vm.headers,"error":_vm.errorRequest,"tableImage":_vm.displayVehicleImages,"src":_vm.serverTotalLogs == 0 ? [] : _vm.logs,"searchText":_vm.$t('tools.project.log.searchText'),"title":_vm.$t(((_vm.$route.meta.i18n) + ".title"))},on:{"close":function($event){_vm.filterTempHandle = null},"search":_vm.searchGateLogData,"link":_vm.linkClickedEvent,"update":function($event){return _vm.updateReportData(true)},"page":_vm.tablePageEvent},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-row',{staticClass:"ma-0",staticStyle:{"max-height":"40px","overflow":"hidden"},attrs:{"align":"start"}},[_c('DateRangeInput',{key:_vm.updatedFilter,attrs:{"dense":true,"filter":true,"loading":false,"dateRange":_vm.filterHandle.dateRange},on:{"update":_vm.updateDateRange}}),_c('FilterInput',{attrs:{"active":_vm.activeFilter,"filter":_vm.activeFilter},on:{"reset":_vm.removeFilterEvent,"update":_vm.applyDataFilterEvent,"close":_vm.cancelFilterEvent},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('FilterSelection',{key:_vm.updateFilter,staticClass:"filter-selection",attrs:{"filter":_vm.filterTempHandle,"whitelistData":_vm.whitelistData},on:{"update":function($event){_vm.filterTempHandle = $event}}})]},proxy:true}])})],1),_c('v-spacer'),_c('IconButton',{staticClass:"mr-2",style:(_vm.displayVehicleImages
                ? 'border-radius: 50%; background-color: var(--v-primary-base);'
                : ''),attrs:{"tooltipDelay":"1000","buttonColor":_vm.displayVehicleImages ? 'white' : '',"tooltipDisabled":false,"tooltipPosition":"l","tooltip":_vm.displayVehicleImages
                ? _vm.$t('tools.project.log.tooltips.images.hide')
                : _vm.$t('tools.project.log.tooltips.images.show'),"icon":"mdi-image-search"},on:{"click":function($event){_vm.displayVehicleImages = !_vm.displayVehicleImages}}}),_c('IconButton',{staticClass:"mr-1",style:(_vm.displayOnSiteVehicles
                ? 'border-radius: 50%; background-color: var(--v-primary-base);'
                : ''),attrs:{"tooltipDelay":"1000","buttonColor":_vm.displayOnSiteVehicles ? 'white' : '',"tooltipDisabled":false,"tooltipPosition":"l","tooltip":_vm.displayOnSiteVehicles
                ? _vm.$t('tools.project.log.tooltips.onSite.hide')
                : _vm.$t('tools.project.log.tooltips.onSite.show'),"icon":"mdi-map-marker"},on:{"click":function($event){_vm.displayOnSiteVehicles = !_vm.displayOnSiteVehicles}}}),_c('v-divider',{staticClass:"ma-1",attrs:{"vertical":""}}),_c('DownloadVehicleLogs',{staticClass:"mr-2 ml-1",attrs:{"violations":false,"headers":_vm.headers,"whitelistData":_vm.whitelistData}})]},proxy:true},{key:"images",fn:function(ref){
                var data = ref.data;
return [(data != null && data.detectionImageUrl != null)?_c('div',{staticClass:"zoom",on:{"click":function($event){return _vm.linkClickedEvent({col: 'licensePlate', data: data})}}},[_c('v-img',{staticStyle:{"border-radius":"10px !important","overflow":"hidden !important"},attrs:{"preload":"","width":"140","src":data.detectionImageUrl}})],1):_vm._e()]}},{key:"barrier",fn:function(ref){
                var data = ref.data;
return [_c('div',{staticStyle:{"width":"30px !important"}},[(data.barrier != null && data.barrier == true)?_c('v-icon',{attrs:{"color":"success","size":"20"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{staticClass:"ml-1",staticStyle:{"opacity":"0.3"},attrs:{"size":"17"}},[_vm._v("mdi-close-circle-outline")])],1)]}},{key:"plate",fn:function(ref){
                var data = ref.data;
return [_c('div',{staticClass:"expand"},[_c('LicensePlateTemplate',{staticStyle:{"cursor":"pointer !important"},attrs:{"dense":"","country":data.country,"licensePlate":data.licensePlate}})],1)]}},{key:"country",fn:function(ref){
                var data = ref.data;
return [_c('country-flag',{staticClass:"country-flag",attrs:{"country":data.country,"size":"normal"}})]}},{key:"direction",fn:function(ref){
                var data = ref.data;
return [(data.direction != 0 && data.direction != null)?_c('v-row',{staticClass:"ma-0"},[(data.direction == 1)?_c('v-icon',{attrs:{"size":"17","color":"black"}},[_vm._v(" mdi-location-enter ")]):_vm._e(),(data.direction == 2)?_c('v-icon',{attrs:{"size":"17","color":"black"}},[_vm._v(" mdi-location-exit ")]):_vm._e(),_c('span',{staticClass:"ml-1"},[(data.direction == 1)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.gateLog.directionEnter')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.gate != null && !_vm.displayVehicleImages),expression:"data.gate != null && !displayVehicleImages"}],staticClass:"ml-1"},[_vm._v("("+_vm._s(data.gate)+")")])]):(data.direction == 2)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.gateLog.directionExit')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.gate != null && !_vm.displayVehicleImages),expression:"data.gate != null && !displayVehicleImages"}],staticClass:"ml-1"},[_vm._v("("+_vm._s(data.gate)+")")])]):_vm._e()])],1):_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.log.gates.unknown'))+" ")])]}},{key:"whitelist",fn:function(ref){
                var data = ref.data;
return [_c('div',[(data.whitelist != null && data.whitelist.length > 0)?_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(data.whitelist)+" ")]):_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t('tools.project.log.table.noVehicleGroup')))])])]}},{key:"vehicleLog",fn:function(ref){
                var data = ref.data;
return [_c('div',{staticStyle:{"width":"50px !important"}},[(
                data.vehicleLog != null &&
                data.vehicleLog.duration != null &&
                data.vehicleLog.duration >= 0
              )?_c('VehicleLogListInformation',{attrs:{"data":data}}):_vm._e(),_c('div',{staticStyle:{"width":"50px !important"}},[(
                  data.vehicleLog != null &&
                  data.vehicleLog.duration != null &&
                  data.vehicleLog.duration >= 0
                )?_c('VehicleLogListInformation',{attrs:{"data":data}}):(
                  data.vehicleLog != null && data.vehicleLog.duration == -1
                )?_c('v-icon',{staticClass:"ml-1",staticStyle:{"opacity":"0.2"},attrs:{"size":"17"}},[_vm._v("mdi-car")]):_c('v-icon',{staticClass:"ml-1",staticStyle:{"opacity":"0.3"},attrs:{"size":"17"}},[_vm._v("mdi-car-off")])],1)],1)]}}])})],1),_c('v-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.gatelogDialog.data != null),expression:"gatelogDialog.data != null"}],attrs:{"width":"500"},model:{value:(_vm.gatelogDialog.status),callback:function ($$v) {_vm.$set(_vm.gatelogDialog, "status", $$v)},expression:"gatelogDialog.status"}},[_c('GateLogInformation',{attrs:{"data":_vm.gatelogDialog.data},on:{"filter":_vm.filterGateLogEvent,"close":function($event){_vm.gatelogDialog.status = false}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }